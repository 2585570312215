section .gallery__img-container {
    display: grid;
    place-items: center;
    margin-inline: auto;
    padding-inline: 0;

}

.wrapper nav {
    display: flex;
    justify-content: center;
    margin-top: 6rem !important;

}

.mobile-filter {
    display: none;
}

.wrapper .items {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin: auto;
    font-size: 15px;
    font-weight: 600;
}

.items span {
    padding: 7px 20px;
    height: 2.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--indigo);
    background-color: var(--light-bg);
    border-radius: 2px;
    border: 1px dashed var(--indigo);
    transition-property: background;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;

}

.items span.active,
.items span:hover {
    color: var(--light-bg);
    background: var(--border-dashed-bg);
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
}

.gallery .image {
    width: calc(85vw / 3.5);
    padding: 7px;
    margin-bottom: 20px;
}

.gallery .image span {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.gallery .image img {
    width: 100%;
    height: 200px;
    vertical-align: middle;
    transition: all 0.3s ease;
}

.gallery .image:hover img {
    transform: scale(1.1);
}

.gallery .image.hide {
    display: none;
}

.gallery .image.show {
    animation: animate 0.4s ease;
}

@keyframes animate {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.preview-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    background: transparent;
    max-width: 900px;
    width: 100%;
    z-index: 999999;
    opacity: 0;
    pointer-events: none;
    border-radius: 3px;
    padding: .1rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.preview-box.show {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.3s ease;
}

.details .cat-title {
    font-family: var(--ff-poppins);
    display: flex;
    font-size: 20px;
    font-weight: 300;
    color: var(--white);
}

.details .cat-title p {
    margin-left: 5px;
}


.preview-box .image-box {
    width: 100%;
    display: flex;
}

.preview-box .image-box:hover {
    cursor: grab;
}

.preview-box .close-icon {
    position: absolute;
    right: -1rem;
    top: -1.6rem;
    height: 2.5rem;
    width: 2.5rem;
    display: grid;
    background-color: #2c2727;
    border-radius: 50%;
    place-items: center;
}

.preview-box .close-icon:hover {
    background-color: var(--orange-soda-dark);

}

.preview-box .close-icon svg {
    font-size: 32px;
    color: var(--white);
}

.preview-box .close-icon:hover {
    cursor: pointer;
}

.image-box img {
    width: 100%;
    border-radius: 0 0 3px 3px;
}

.shadow {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: none;
    background: rgba(0, 0, 0, 0.4);
}

.shadow.show {
    display: block;
}

@media (max-width: 1000px) {
    .gallery .image {
        width: calc(100% / 3);
    }

    .wrapper .items {
        display: flex;
        gap: 1rem;
    }
}

@media (max-width: 800px) {
    .gallery .image {
        width: calc(100% / 2);
    }

    .items span {
        padding: 5px 8px;
    }

    nav .items span {
        font-size: 11px;
        line-height: 10px;
        text-align: center;
    }

    .preview-box {
        padding: 8px 10px;
        width: 85%;
    }

    .preview-box .close-icon {
        position: absolute;
        right: -.5rem;
        top: -0.7rem;
    }

}

@media (max-width: 700px) {
    .wrapper nav .items {
        max-width: 600px;
    }
}

@media (max-width: 600px) {
    .wrapper {
        margin: 30px auto;
    }

    .wrapper nav .desktop-filter {
        display: none;
    }

    .wrapper nav .items {
        flex-wrap: wrap;
        justify-content: center;
    }

    nav .items span {
        margin: 5px;
    }

    .mobile-filter {
        display: block;
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px;
    }

    .gallery .image {
        width: 100%;
    }

}