/* Featured Projects Section */
.featured-projects {
  margin-bottom: 48px;
}

.featured-projects h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.featured-carousel {
  position: relative;
  padding: 0 40px;
}

.carousel-container {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 16px;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.carousel-container::-webkit-scrollbar {
  height: 6px;
}

.carousel-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.carousel-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.featured-project-card {
  flex: 0 0 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.featured-project-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 16px;
}

.featured-project-card h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

/* Carousel Navigation Buttons */
.carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-nav-button:hover {
  background: #f5f5f5;
}

.carousel-nav-button.prev {
  left: 0;
}

.carousel-nav-button.next {
  right: 0;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 32px 0;
}

.pagination-button {
  padding: 8px 16px;
  border-radius: 8px;
  background: #f5f5f5;
  border: none;
  cursor: pointer;
}

.pagination-button:hover:not(:disabled) {
  background: #e5e5e5;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 16px;
}

/* Project Grid Modifications */
.project-content__item {
  margin-bottom: 32px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .featured-project-card {
    flex: 0 0 300px;
  }

  .carousel-nav-button {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 480px) {
  .featured-project-card {
    flex: 0 0 260px;
  }

  .pagination-controls {
    gap: 8px;
  }

  .pagination-button {
    padding: 6px 12px;
  }
}

.project-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  margin: 20px 0;
  max-width: 550px;
  text-align: left;
}

.filter-select {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
}

.filter-select:focus {
  outline: none;
  border-color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 32px 0;
}

.page-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.page-btn:hover:not(:disabled) {
  background-color: #e5e5e5;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-info {
  font-size: 16px;
}

@media (max-width: 768px) {
  .project-filter {
    text-align: center;
    margin: 16px 0;
  }

  .pagination {
    gap: 8px;
  }

  .page-btn {
    padding: 6px 12px;
  }
}