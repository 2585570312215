* {
    box-sizing: border-box;
    margin: 0;
}

:root {
    --heights: 100vh;
    --widths: 100%;
}

.slider-container {
    height: var(--heights);
    width: var(--widths);
    margin: auto;
    overflow: hidden;
    z-index: -1;
}

.active {
    display: inline-block;
}

.inactive {
    display: none;
}

.slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
}

.slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

@media screen and (max-width: 975.05px) {
    .slider-container {
        height: auto;
        margin-bottom: -14rem;

    }

    .slider-container .slide-image {
        position: absolute;
        height: auto;
        background-position: center;
        object-fit: scale-down;
    }

    .prev,
    .next {
        bottom: 50% !important;
    }

    .all-dots {
        left: 0;
        right: 0;
        bottom: 25% !important;
        z-index: 99999;
    }

}

@media screen and (max-width: 767px) {
    .all-dots>* {
        display: none !important;
    }
}

@media screen and (max-width: 657.75px) {
    .slider-container {
        max-height: 50vh !important;
        margin-bottom: -1rem;
    }

    .prev,
    .next {
        top: 30% !important;
        width: 1rem !important;
        height: 1rem !important;
        font-size: 20px !important;
        padding: 1rem !important;
    }
}

@media screen and (max-width: 375.75px) {
    .slider-container {
        margin-bottom: -16rem;
    }

    .prev,
    .next {
        top: 20% !important;
    }
}

.slide-title,
.slide-text {
    display: none;
    width: 100%;
    height: 100%;
    color: #17a2b8;
    font-weight: 900;
    font-size: 80px;
    position: absolute;
    text-align: center;
    top: 30%;
    z-index: 10;
}

.slide-text {
    top: 65%;
    font-size: 2rem;
    font-weight: 400;
    font-size: 14px !important;
}

.prev,
.next {
    opacity: 0.8;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    bottom: 15%;
    width: 2rem;
    margin-left: 3rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    display: flex;
    color: var(--color-info-dark);
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    border-radius: 9999px;
    border: 2px solid #d9d9d9;
    transition: all 0.5s ease-in;
}

.prev:hover,
.next:hover {
    opacity: 1;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transform: scale(1.1);
}

.next {
    right: 0;
    margin-right: 3rem;
}


.all-dots {
    width: 100%;
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: -10rem;
    justify-content: center;
    z-index: 200;
}

.dot {
    cursor: pointer;
    height: 0.3rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1px;
    display: inline-block;
}

.active-dot,
.dot:hover {
    background-color: rgba(255, 255, 255, 0.6);
}